<template>
  <div v-if="volume" class="pb-5">
    <hero :volume="volume" />

    <div class="thanks-card" v-if="isThanks">
      <div class="notification is-success has-text-centered" style="max-width: 450px;">
        <fa-icon :icon="['fas', 'smile']" size="5x" class="big-icon" />
        <p class="title">THANK YOU!</p>
        <p class="subtitle is-6"><span>アンケートへのご協力</span><span class="is-inline-block">ありがとうございました。</span></p>
      </div>

      <div class="buttons is-centered mt-6">
        <router-link class="button is-primary is-inverted" :to="{ name: 'volume', params: {volumeId: volumeId} }">トップへ</router-link>
      </div>
    </div>

    <div class="form-container" style="max-width: 750px;" v-else-if="!survey.respondent">
      <div class="notification is-info is-light p-5 mb-5">
        <div class="title is-4" style="margin-bottom: 2rem;">読者アンケート</div>
        <div class="subtitle is-6">
          <b class="mr-1">{{ volume.title }}</b>はいかがでしたか？<br>今後のSHIFTと広報活動に活かさせていただきますので、ぜひご感想をお聞かせください😄
        </div>
      </div>

      <div class="buttons is-centered is-fullwidth">
        <a @click="survey.respondent = 'employee'" class="button is-medium is-primary respondent-button">
          <div>
            <p class="is-size-3 has-text-weight-bold">従業員</p>
            <p>の方はこちら</p>
          </div>
        </a>
        <a @click="survey.respondent = 'family'" class="button is-medium is-success respondent-button">
          <div>
            <p class="is-size-3 has-text-weight-bold">ご家族</p>
            <p>の方はこちら</p>
          </div>
        </a>
      </div>
    </div>
    
    <div class="form-container" style="max-width: 750px;" v-else>
      <nav class="breadcrumb mb-6" aria-label="breadcrumbs">
        <ul>
          <li><a href="#" @click="survey.respondent = null">アンケート</a></li>
          <li class="is-active" v-if="survey.respondent === 'employee'">
            <a href="#" aria-current="page">従業員の方</a>
          </li>
          <li class="is-active" v-else-if="survey.respondent === 'family'">
            <a href="#" aria-current="page">ご家族の方</a>
          </li>
        </ul>
      </nav>

      <ValidationObserver ref="validationObserver" tag="form">
        <!-- 従業員のみ -->
        <ValidationProvider rules="required" v-slot="{ errors }" name="name" slim v-if="survey.respondent === 'employee'">
          <b-field label="お名前（フルネーム）" :type="{'is-danger': errors[0]}" :class="{'has-error': errors[0]}" :message="errors" required>
            <b-input v-model="survey.name" placeholder="シフト 太郎"></b-input>
          </b-field>
        </ValidationProvider>

        <!-- 家族のみ -->
        <div class="columns my-0" v-if="survey.respondent === 'family'">
          <div class="column py-0 is-8">
            <b-field label="SHIFTで働くご家族のお名前">
              <b-input v-model="survey.name" placeholder="シフト 太郎"></b-input>
            </b-field>
          </div>

          <div class="column py-0 is-4">
            <ValidationProvider rules="required" v-slot="{ errors }" slim>
              <b-field label="当人との間柄" :type="{'is-danger': errors[0]}" :class="{'has-error': errors[0]}" :message="errors" required>
                <b-input v-model="survey.relationship" placeholder="妻、夫、娘、息子など"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
        </div>

        <ValidationProvider rules="required" v-slot="{ errors }" slim>
          <b-field label="社内報を誰と一緒にご覧になりましたか？" :type="{'is-danger': errors[0]}" :class="{'has-error': errors[0]}" :message="errors" required>
            <section>
              <b-field v-for="(v, i) in ['お一人で', 'ご家族と', 'その他']" :key="i">
                <b-radio v-model="survey.withWhom" :native-value="v">{{ v }}</b-radio>
              </b-field>
            </section>
          </b-field>
        </ValidationProvider>

        <b-field label="どのコンテンツが良かったですか？（複数選択可）" v-if="volume.surveyContents">
          <section>
            <b-field v-for="(v, i) in volume.surveyContents" :key="i">
              <b-checkbox v-model="survey.favorites" :native-value="v">{{ v }}</b-checkbox>
            </b-field>
          </section>
        </b-field>

        <!-- Vol.3のみ -->
        <template v-if="volumeId === 'At2B0awimh3JAS1Qa3Xa'">
          <ValidationProvider rules="required" v-slot="{ errors }" slim>
            <b-field label="P23  <動画>「シフトグラム～クレドを動くピクトグラムで表現～」をご覧になりましたか" :type="{'is-danger': errors[0]}" :class="{'has-error': errors[0]}" :message="errors" required>
              <section>
                <b-field v-for="(v, i) in ['はい', 'いいえ']" :key="i">
                  <b-radio v-model="survey.vol3ShiftgramWatched" :native-value="v">{{ v }}</b-radio>
                </b-field>
              </section>
            </b-field>
          </ValidationProvider>

          <!-- 従業員のみ -->
          <template v-if="survey.respondent === 'employee'">
            <b-field>
              <template slot="label">上記で「はい」とお答えされた方への質問です。<br>動画を見て当てはまるものを教えてください。（複数選択可）</template>
              <section>
                <b-field v-for="(v, i) in ['クレドをより深く理解することができた', 'クレドに興味を持った', 'クレドを業務に活かそうと思った', 'クレドに対する想いや興味に変化はなかった', 'その他（自由回答）']" :key="i">
                  <b-checkbox v-model="survey.vol3ShiftgramImpression" :native-value="v">{{ v }}</b-checkbox>
                </b-field>
                <b-field style="padding-left: 2rem;">
                  <b-input v-model="survey.vol3ShiftgramImpressionOther"></b-input>
                </b-field>
              </section>
            </b-field>
          </template>

          <!-- 家族のみ -->
          <template v-if="survey.respondent === 'family'">
            <b-field>
              <template slot="label">上記で「はい」とお答えされた方への質問です。<br>SHIFTやクレドに対する印象を教えてください。（自由回答）</template>
              <b-input v-model="survey.vol3CredoImpression" rows="5" type="textarea"></b-input>
            </b-field>
          </template>
        </template>

        <!-- Vol.5以降に追加 -->
        <template>
          <ValidationProvider rules="required" v-slot="{ errors }" slim>
            <b-field label="動画コンテンツをご覧になりましたか" :type="{'is-danger': errors[0]}" :class="{'has-error': errors[0]}" :message="errors" required>
              <section>
                <b-field v-for="(v, i) in ['はい', 'いいえ']" :key="i">
                  <b-radio v-model="survey.videoWatched" :native-value="v">{{ v }}</b-radio>
                </b-field>
              </section>
            </b-field>
          </ValidationProvider>

          <!-- 従業員のみ -->
          <template v-if="survey.respondent === 'employee'">
            <b-field label="上記で「はい」とお答えされた方への質問です。動画を見て当てはまるものを教えてください。">
              <section>
                <b-field v-for="(v, i) in ['SHIFTを深く理解することができ、従業員に対して親近感が沸いた', 'SHIFTの規模感が分かり、会社に対する安心感・信頼感がより強くなった', '想いや興味に変化はなかった', 'その他']" :key="i">
                  <b-radio v-model="survey.videoWatchedNote" :native-value="v">{{ v }}</b-radio>
                </b-field>
              </section>
            </b-field>
          </template>

          <!-- 家族のみ -->
          <template v-if="survey.respondent === 'family'">
            <b-field label="上記で「はい」とお答えされた方への質問です。動画を見て当てはまるものを教えてください。">
              <section>
                <b-field v-for="(v, i) in ['SHIFTの規模感が分かり、会社に対する安心感・信頼感がより強くなった', '想いや興味に変化はなかった', 'その他']" :key="i">
                  <b-radio v-model="survey.videoWatchedNote" :native-value="v">{{ v }}</b-radio>
                </b-field>
              </section>
            </b-field>
          </template>
        </template>

        <!-- 従業員のみ -->
        <ValidationProvider rules="required" v-slot="{ errors }" slim v-if="survey.respondent === 'employee'">
          <b-field label="社内報を通じて、SHIFTに対する理解は深まりましたか？" :type="{'is-danger': errors[0]}" :class="{'has-error': errors[0]}" :message="errors" required>
            <section>
              <b-field v-for="(v, i) in ['深まった', '変わらない']" :key="i">
                <b-radio v-model="survey.understand" :native-value="v">{{ v }}</b-radio>
              </b-field>
            </section>
          </b-field>
        </ValidationProvider>

        <!-- 家族のみ -->
        <ValidationProvider rules="required" v-slot="{ errors }" slim v-if="survey.respondent === 'family'">
          <b-field label="社内報を通じて、SHIFTに対する印象に変化はありましたか？" :type="{'is-danger': errors[0]}" :class="{'has-error': errors[0]}" :message="errors" required>
            <section>
              <b-field v-for="(v, i) in ['よくなった', 'とくに変化なし', '悪くなった']" :key="i">
                <b-radio v-model="survey.impression" :native-value="v">{{ v }}</b-radio>
              </b-field>
            </section>
          </b-field>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors }" slim>
          <b-field label="全体を通しての満足度を教えてください" :type="{'is-danger': errors[0]}" :class="{'has-error': errors[0]}" :message="errors" required>
            <b-rate v-model="survey.rating" icon-pack="fas" size="is-medium" spaced></b-rate>
          </b-field>
        </ValidationProvider>

        <b-field label="その他、ご感想やメッセージ、次号以降で見たいコンテンツなどありましたらご記入ください">
          <b-input v-model="survey.messages" rows="5" type="textarea"></b-input>
        </b-field>

        <!-- 従業員のみ -->
        <!-- <b-field label="今後、ご自宅への社内報の郵送を望まない方は、こちらにチェックしてください" v-if="survey.respondent === 'employee'">
          <b-checkbox v-model="survey.stopSending" true-value="×" false-value="">郵送を希望しない</b-checkbox>
        </b-field> -->

        <div>
          <b-button type="is-primary is-medium" icon-pack="fas" icon-left="paper-plane" class="send-button" @click="submit" :loading="isProcessing">送信</b-button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>


<script>
import { firebase, db } from '@/main'
import Hero from '@/components/Hero'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

export default {
  components: { Hero },
  data() {
    return {
      volumeId: this.$route.params.volumeId,
      volume: null,
      survey: {
        name: "",
        respondent: this.$route.query.respondent,
        relationship: "",
        withWhom: "",
        favorites: [],
        vol3ShiftgramWatched: "",
        vol3ShiftgramImpression: [],
        vol3ShiftgramImpressionOther: "",
        vol3CredoImpression: "",
        videoWatched: "",
        understand: "",
        impression: "",
        rating: null,
        messages: "",
        stopSending: "",
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      isProcessing: false,
      isThanks: false,
    }
  },
  firestore() {
    const volumeRef = db.collection('volumes').doc(this.volumeId)

    return {
      volume: volumeRef,
    }
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.validationObserver.validate()
      if (!isValid) {
        this.focusError()
        return
      }

      this.isProcessing = true

      this.$firestoreRefs.volume.collection('surveyResponses')
      .add(this.survey)
      .then(() => {
        this.isProcessing = false
        this.isThanks = true
      })
      .catch((error) => {
        this.isProcessing = false
        console.log(error)
      })
    },
    focusError() {
      const field = this.$refs.validationObserver.$el.querySelector('.field.has-error')
      const input = field.querySelector('input')
      field.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      if (input) input.focus()
    },
  }
}
</script>


<style lang='scss' scoped>
  .breadcrumb {
    background-color: whitesmoke;
    padding: 0.5rem 0.75rem;
    border-radius: 3px;
  }

  .form-container {
    padding: 0 2.5rem 2.5rem 2.5rem;
    margin: 0 auto;

    ::v-deep form {
      .columns {
        margin-bottom: 1.25rem;
      }

      > .field, .column > .field {
        margin-bottom: 2rem;

        > .label {
          margin-bottom: 0.5rem;

          + section {
            margin-top: 0.75rem;
          }
        }

        &[required] > .label:after {
          content: "＊";
          color: #f14668;
          margin-left: 0.25rem;
        }

        .b-radio.is-primary {
          border: 1px solid #dbdbdb;
        }

        .rate .icon {
          height: 2.25rem;

          .fa-star {
            font-size: 1.5rem;
          }
        }
      }

      .send-button {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    .respondent-button {
      height: 8rem;
      width: 15rem;
      margin: 0.75rem !important;
    }

    @media screen and (max-width: 400px) {
      .respondent-button {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .thanks-card {
    padding: 0 1.5rem;
    margin-top: 1rem;

    .notification {
      border: 8px solid rgba(255, 255, 255, 0.9);
      border-radius: 3px;
      box-shadow: rgba(72, 199, 116, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      margin: 0 auto;
      padding: 2rem;
    }

    .big-icon {
      margin-bottom: 1rem;
    }

    .title {
      margin-bottom: 2rem;
    }
  }
</style>
